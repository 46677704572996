import React from 'react';
import { Link } from 'react-router-dom';
import { portfolioItems, bestsellerItems } from '../components/HomeData/HomeData';

const Home = () => {
  return (
    <div>
      {/* Banner Section Start */}
      <div className="container-xxl py-5 bg-primary hero-header mb-5">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-lg-6 text-center text-lg-start">
              <h2 className="text-white mt-4 fw-bold animated zoomIn">Discover Premium Car Accessories</h2>
              <p className="text-white pb-3 mt-4 animated zoomIn">
                Browse our selection of top-quality car accessories to elevate your driving experience.
              </p>
              <Link to="/" className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">Read More</Link>
              <Link to="/contact" className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</Link>
            </div>
            <div className="col-lg-6 text-center text-lg-start">
              <img className="img-fluid" src="img/home/hero/hero.svg" alt="Premium Car Accessories" />
            </div>
          </div>
        </div>
      </div>
      {/* Banner Section End */}

      {/* About Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mt-4 pb-2">
                <h6 className="position-relative text-primary ps-4">About Us</h6>
                <h2 className="mt-2">Offering Quality Services at Competitive Prices</h2>
              </div>
              <p className="mt-4">
                At LMTEK, we prioritize providing top-quality services at competitive prices. Our focus is on delivering the latest technology to our customers while upholding stringent quality standards. By considering customer demands, market trends, and supplier-provided product features, we source merchandise either through import or local suppliers of established brands. Our aim is to bring the best products to market for our valued customers.
              </p>
              <div className="row g-3">
                <div className="col-sm-6">
                  <h6 className="mb-3"><i className="fa fa-check text-primary me-2" />Award Winning</h6>
                  <h6 className="mb-0"><i className="fa fa-check text-primary me-2" />Competitive Pricing</h6>
                </div>
                <div className="col-sm-6">
                  <h6 className="mb-3"><i className="fa fa-check text-primary me-2" />Top-quality Services</h6>
                  <h6 className="mb-0"><i className="fa fa-check text-primary me-2" />Best Products</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <Link className="btn btn-primary rounded-pill px-4 me-3" to="/">Read More</Link>
                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-twitter" /></a>
                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-instagram" /></a>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/home/about/home-about.jpeg" alt="About Us" />
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Service Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-bolt fa-2x" />
                </div>
                <h5 className="mb-3">ADVANCE MODERN TECHNOLOGY</h5>
                <h5 className="mb-3">A Wide Product Range</h5>
                <p>Our wide range of products is built using the most advanced technology, offering you an edge over traditional products.</p>
                <Link className="btn px-3 mt-auto mx-auto" to="/">Read More</Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-home fa-2x" />
                </div>
                <h5 className="mb-3">HIGH PERFORMANCE DELIVERED</h5>
                <h5 className="mb-3">Thoughtfully Designed Products</h5>
                <p>Our products are thoughtfully designed to deliver high performance, addressing the evolving needs of our customers.</p>
                <Link className="btn px-3 mt-auto mx-auto" to="/">Read More</Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                <div className="service-icon flex-shrink-0">
                  <i className="fa fa-plug fa-2x" />
                </div>
                <h5 className="mb-3">PLUG AND PLAY INTERFACE</h5>
                <h5 className="mb-3">Perfect Fit & Finish</h5>
                <p>Our products offer a plug and play interface, ensuring a perfect fit and finish for seamless integration.</p>
                <Link className="btn px-3 mt-auto mx-auto" to="/">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service Section End */}

      {/* Carousel Section Start */}
      <div className="py-lg-5">
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
                <img src={`img/home/carousel/carousel-${index + 1}.jpg`} className="d-block w-100" alt={`Carousel Image ${index + 1}`} />
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon bg-dark rounded" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon bg-dark rounded" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel Section End */}

      {/* Product Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="position-relative d-inline text-primary ps-4">Our Products</h6>
            <h2 className="mt-2">Recently Launched Products</h2>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="border-bottom d-flex justify-content-center mb-4">
              <ul className="nav list-inline mb-4" id="portfolio-flters" role="tablist">
                <li className="btn px-3 pe-4 active">
                  <span data-bs-toggle="tab" data-bs-target="#recent" type="button" role="tab" aria-selected="true">Best Seller</span>
                </li>
                <li className="btn px-3 pe-4">
                  <span data-bs-toggle="tab" data-bs-target="#bestseller" type="button" role="tab" aria-selected="false">New Product</span>
                </li>
                <li className="btn px-3 pe-4">
                  <Link to="/product" style={{ color: "#fc8e41" }}>All Products</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="portfolio-tabContent">
            <div id="recent" className="tab-pane fade show active" role="tabpanel">
              <div className="row g-4 portfolio-container">
                {portfolioItems.map((item, index) => (
                  <div key={item.id} className={`col-lg-4 col-md-6 portfolio-item wow zoomIn`} data-wow-delay={`${index * 0.2}s`}>
                    <div className="position-relative rounded overflow-hidden shadow-lg">
                      <img className="img-fluid w-100" src={item.image} alt={item.name} style={{ height: "300px" }} />
                      <div className="portfolio-overlay">
                        <a className="btn btn-light" href={item.image} data-lightbox="portfolio">
                          <i className="fa fa-plus fa-2x text-primary" />
                        </a>
                        <div className="mt-auto">
                          <small className="text-white"><i className="fa fa-folder me-2" />{item.category}</small>
                          <Link className="h5 d-block text-white mt-1 mb-0" to={item.link}>{item.name}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div id="bestseller" className="tab-pane fade" role="tabpanel">
              <div className="row g-4 portfolio-container">
                {bestsellerItems.map((item, index) => (
                  <div key={index} className={`col-lg-4 col-md-6 portfolio-item wow zoomIn`} data-wow-delay={`${index * 0.2}s`}>
                    <div className="position-relative rounded overflow-hidden shadow-lg">
                      <img className="img-fluid w-100 p-4 shadow-lg" src={item.image} alt={item.altText} style={{ height: "300px" }} />
                      <div className="portfolio-overlay">
                        <a className="btn btn-light" href={item.image} data-lightbox="portfolio">
                          <i className="fa fa-plus fa-2x text-primary" />
                        </a>
                        <div className="mt-auto">
                          <small className="text-white"><i className="fa fa-folder me-2" />{item.category}</small>
                          <Link className="h5 d-block text-white mt-1 mb-0" to={item.link}>{item.name}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Product Section End */}

      {/* Latest Products Section Start */}
      <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
          <h5 className="text-primary text-center">Introducing the Latest</h5>
          <h1 className="display-6 text-uppercase text-center mb-5">Exciting New <span className="text-primary">Product Range</span></h1>
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <img className="w-75 mb-4" src="img/home/about/about.png" alt="Product Image" />
              <p>
                At LMTEK, our aim has always been to deliver, innovate, and offer the best solutions to elevate the automobile experience. With this vision, we have launched our exciting new products in the range. All these products come with the most advanced technology and modern features.
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 mb-2">
              <div className="d-flex align-items-center bg-light mb-4 shadow-lg">
                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4" style={{ width: '100px', height: '100px' }}>
                  <i className="fa fa-2x fa-headset text-white" />
                </div>
                <h4 className="text-center m-2">1200+ Products Installed</h4>
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <div className="d-flex align-items-center bg-light mb-4 shadow-lg">
                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4" style={{ width: '100px', height: '100px' }}>
                  <i className="fa fa-2x fa-car text-white" />
                </div>
                <h4 className="text-center m-2">1200+ Dealers Across India</h4>
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <div className="d-flex align-items-center bg-light mb-4 shadow-lg">
                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4" style={{ width: '100px', height: '100px' }}>
                  <i className="fa fa-2x fa-users text-white" />
                </div>
                <h4 className="text-center m-2">2.5k+ Happy Customers</h4>
              </div>
            </div>
            <div className="col-lg-3 mb-2">
              <div className="d-flex align-items-center bg-light mb-4 shadow-lg">
                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary ml-n4 mr-4" style={{ width: '100px', height: '100px' }}>
                  <i className="fa fa-2x fa-map-marker-alt text-white" />
                </div>
                <h4 className="text-center m-2">7+ Years of Experience</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Latest Products Section End */}

      {/* Why Choose Us Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-6">
              <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/home/why/why.jpeg" alt="Why Choose Us" />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mt-4 pt-2">
                <h6 className="position-relative text-primary ps-4">Why Choose Us?</h6>
                <h2 className="pb-4">Your Trusted Partner for Quality and Value</h2>
              </div>
              <p className="mt-4">
                At our company, we prioritize the use of high-quality materials and skilled craftsmanship to deliver top-tier automobile interior and exterior parts. Our goal is to provide premium products at affordable prices, ensuring our customers receive excellent value for their investment.
              </p>
              <div className="row g-3">
                <div className="col-sm-6">
                  <h6 className="mb-0"><i className="fa fa-check text-primary me-2" />Budget-Friendly</h6>
                  <h6 className="mt-3"><i className="fa fa-check text-primary me-2" />Authentic Products</h6>
                </div>
                <div className="col-sm-6">
                  <h6 className="mb-0"><i className="fa fa-check text-primary me-2" />Innovative Design</h6>
                  <h6 className="mt-3"><i className="fa fa-check text-primary me-2" />Quality Services</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <Link className="btn btn-primary rounded-pill px-4 me-3" to="#">Read More</Link>
                {/* Uncomment and use these buttons if needed */}
                {/* <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-facebook-f" /></a>
                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-twitter" /></a>
                <a className="btn btn-outline-primary btn-square me-3" href="#"><i className="fab fa-instagram" /></a>
                <a className="btn btn-outline-primary btn-square" href="#"><i className="fab fa-linkedin-in" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why Choose Us Section End */}
    </div>
  );
};

export default Home;
