import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Contact = () => {
  const [error, setError] = useState(false);
  const [addContact, setAddContact] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!addContact.name || !addContact.email || !addContact.mobileNumber || !addContact.subject || !addContact.message) {
      setError(true);
      return;
    }

    try {
      const response = await axios.post('http://13.201.225.123:3000/website/contact-us', addContact);
      if (response.status === 201) {
        toast.success('Message sent successfully!', { autoClose: 2000 });
        setError(false);
        setAddContact({
          name: '',
          email: '',
          mobileNumber: '',
          subject: '',
          message: '',
        });
      } else {
        toast.error('Failed to send message. Please try again later.', { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      toast.error('Failed to send message. Please try again later.');
    }
  };

  return (
    <div>
      <ToastContainer />
      {/* Main container */}
      <div className="container-xxl position-relative p-0">
        <div className="container-xxl py-5 bg-primary hero-header mb-5">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white animated zoomIn">Contact Us</h1>
                <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                    <li className="breadcrumb-item text-white active fw-bold" aria-current="page">Contact</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="position-relative d-inline text-primary ps-4">Contact Us</h6>
                <h2 className="mt-2">Contact For Any Query</h2>
              </div>
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" onChange={handleInputChange} value={addContact.name} className="form-control" name="name" placeholder="Your Name" />
                        {error && !addContact.name && <span className='text-danger'>Please enter your name</span>}
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" onChange={handleInputChange} value={addContact.email} className="form-control" name="email" placeholder="Your Email" />
                        {error && !addContact.email && <span className='text-danger'>Please enter your email</span>}
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="text" onChange={handleInputChange} value={addContact.subject} className="form-control" name="subject" placeholder="Subject" />
                        {error && !addContact.subject && <span className='text-danger'>Please enter your subject</span>}
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="number" onChange={handleInputChange} value={addContact.mobileNumber} className="form-control" name="mobileNumber" placeholder="Please enter number" />
                        {error && !addContact.mobileNumber && <span className='text-danger'>Please enter your number</span>}
                        <label htmlFor="contactNumber">Contact Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" onChange={handleInputChange} value={addContact.message} placeholder="Leave a message here" name="message" style={{ height: '150px' }} />
                        {error && !addContact.message && <span className='text-danger'>Please enter your message</span>}
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}

      {/* Contact Map Area Start */}
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.898501898795!2d77.2280545!3d28.662757600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd7c66a6e06f%3A0x6dd3945413eb2d34!2sLMTek!5e0!3m2!1sen!2sin!4v1713382616102!5m2!1sen!2sin"
          className="contact__map--iframe"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* Contact Map Area End */}
    </div>
  );
};

export default Contact;
