
export  const productData = [
    {
      id: 1,
      image: 'img/home/product/1.jpg',
      category: 'Fully Imported car speaker',
      name: 'Car Speaker',
      delay: '0.1s',
    },
    {
      id: 2,
      image: 'img/home/product/2.jpg',
      category: '7 inch car screen',
      name: 'car screen',
      delay: '0.3s',
    },
    {
      id: 3,
      image: 'img/home/product/3.jpg',
      category: '8 inch basstube with amplifier',
      name: 'car basstube with amplifier ',
      delay: '0.6s',
    },
    {
        id:4,
        image: 'img/home/product/4.jpg',
        category: '9 Android screen',
        name: 'Car Androidscreen',
        delay: '0.6s',
      },
      {
        id:5,
        image: 'img/home/product/5.jpg',
        category: 'car Touch 9 & 10 Android screen',
        name: 'Car Touch',
        delay: '0.6s',
      },
      {
        id:6,
        image: 'img/home/product/6.jpg',
        category: '10 inch basstube with amplifier',
      name: 'car basstube with amplifier ',
        delay: '0.6s',
      },
      {
        id:7,
        image: 'img/home/product/7.jpg',
        category: 'Imported 10 inch metal basstube',
        name: ' Car metal basstube',
        delay: '0.6s',
      },

      {
        id:8,
        image: 'img/home/product/8.jpg',
        category: '13 inch android screen ',
        name: 'Android screen ',
        delay: '0.6s',
      },
      {
        id:9,
        image: 'img/home/product/9.jpg',
        category: '360 degree camera 2',
        name: 'car 360 degree camera 2',
        delay: '0.6s',
      },
      {
        id:10,
        image: 'img/home/product/10.jpg',
        category: '360 degree camera without DVR',
        name: 'car camera without DVR',
        delay: '0.6s',
      },
      {
        id:11,
        image: 'img/home/product/11.jpg',
        category: 'Ahd camera With moving line',
        name: 'AHD camera',
        delay: '0.6s',
      },
      {
        id:12,
        image: 'img/home/product/12.jpg',
        category: 'Amplifier for basstube',
        name: 'Amp for basstube',
        delay: '0.6s',
      },

      {
        id:13,
        image: 'img/home/product/13.jpg',
        category: 'Amplifier for android',
        name: 'car amplifier for android',
        delay: '0.6s',
      },

      {
        id:14,
        image: 'img/home/product/14.jpg',
        category: 'car led 150 watt',
        name: 'car led ',
        delay: '0.6s',
      },

      {
        id:15,
        image: 'img/home/product/15.jpg',
        category: 'car led 260 watt',
        name: 'car led 260 ',
        delay: '0.6s',
      },

      {
        id:16,
        image: 'img/home/product/16.jpg',
        category: 'car mp3',
        name: 'car mp3',
        delay: '0.6s',
      },


      {
        id:17,
        image: 'img/home/product/17.jpg',
        category: 'dashboard screen',
        name: 'car dashboard screen',
        delay: '0.6s',
      },
      {
        id:18,
        image: 'img/home/product/18.jpg',
        category: 'led camera metal body',
        name: 'Led camera metal body',
        delay: '0.6s',
      },
      {
        id:19,
        image: 'img/home/product/19.jpg',
        category: 'led camera moving body',
        name: 'Led camera moving body',
        delay: '0.6s',
      },
      {
        id:20,
        image: 'img/home/product/20.jpg',
        category: 'mirror screen',
        name: 'Car Mirror screen',
        delay: '0.6s',
      },

      {
        id:21,
        image: 'img/home/product/21.jpg',
        category: 'mp5 audio player',
        name: 'car mp5 audio player',
        delay: '0.6s',
      },
      {
        id:22,
        image: 'img/home/product/22.jpg',
        category: 'parking sensor',
        name: 'car parking sensor',
        delay: '0.6s',
      },
      {
        id:23,
        image: 'img/home/product/23.jpg',
        category: 'picaso car play box',
        name: 'picaso car play box',
        delay: '0.6s',
      },
      {
        id:24,
        image: 'img/home/product/24.jpg',
        category: 'px3',
        name: 'car px3',
        delay: '0.6s',
      },
      {
        id:25,
        image: 'img/home/product/25.jpg',
        category: 'screen',
        name: 'car screen',
        delay: '0.6s',
      },
      {
        id:26,
        image: 'img/home/product/26.jpg',
        category: 'truck camera 2',
        name: 'Truck camera 2',
        delay: '0.6s',
      },
      {
        id:27,
        image: 'img/home/product/27.jpg',
        category: 'truck camera',
        name: 'Truck camera',
        delay: '0.6s',
      },
      {
        id:28,
        image: 'img/home/product/28.jpg',
        category: 'underseat basstube',
        name: 'Underseat basstube',
        delay: '0.6s',
      },
      {
        id:29,
        image: 'img/home/product/29.jpg',
        category: 'wiring kit',
        name: 'wiring kit',
        delay: '0.6s',
      },

{
        id:30,
        image: 'img/home/product/30.jpeg',
        category: '6 inch speaker',
        name: '6 inch speaker',
        delay: '0.6s',
      },
      {
        id:31,
        image: 'img/home/product/31.jpeg',
        category: '13 andriod screen',
        name: '13 andriod screen',
        delay: '0.6s',
      },














   
  ];

