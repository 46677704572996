import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation(); 
  const [activeLink, setActiveLink] = useState("Home");

  // Check the current route and set the active link accordingly
  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setActiveLink('Home');
        break;
      case '/about':
        setActiveLink('About');
        break;
      case '/product':
        setActiveLink('Product');
        break;   
      case '/contact':
        setActiveLink('Contact');
        break;
      default:
        setActiveLink('Home'); // Default to Home for unknown routes
    }
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="container-xxl position-relative p-0">
      <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        
        {/* Logo */}
        <Link to="/" className="navbar-brand p-0">
          <img src="img/logo/logo.svg" className="m-0" alt="Logo" />
        </Link>

        {/* Mobile Menu Toggle Button */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="fa fa-bars" />
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a href="/" onClick={() => handleLinkClick("Home")} className={activeLink === "Home" ? "nav-link active" : "nav-link"}>Home</a>
            <a href="/about" onClick={() => handleLinkClick("About")} className={activeLink === "About" ? "nav-link active" : "nav-link"}>About</a>
            <a href="/product" onClick={() => handleLinkClick("Product")} className={activeLink === "Product" ? "nav-link active" : "nav-link"}>Product</a>
            <a href="/contact" onClick={() => handleLinkClick("Contact")} className={activeLink === "Contact" ? "nav-link active" : "nav-link"}>Contact</a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
