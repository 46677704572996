import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="container-fluid bg-primary text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5 px-lg-5">
        <div className="row g-5">
          
          {/* Company Information */}
          <div className="col-md-6 col-lg-6">
            <h5 className="text-white mb-4">LMTEK - Drive With Style</h5>
            <p>
              "We are India's go-to online store for high-quality electronic components. Explore our diverse selection, curated to meet the needs of customers and electronic service groups alike. Trust us for reliability, quality, and exceptional service."
            </p>
            <div className="d-flex pt-2">
              {/* Social Media Links */}
              <a className="btn btn-outline-light btn-social m-2" href="#" ><i className="fab fa-twitter" /></a>
              <a className="btn btn-outline-light btn-social m-2" href="#"><i className="fab fa-facebook-f" /></a>
              <a className="btn btn-outline-light btn-social m-2" href="#"><i className="fab fa-instagram" /></a>
            </div>
          </div>
          
          {/* Quick Links */}
          <div className="col-md-6 col-lg-2">
            <h5 className="text-white mb-4">Our Company</h5>
            <a className="btn btn-link" href="/">Home</a>
            <a className="btn btn-link" href="/about">About</a>
            <a className="btn btn-link" href="/product">Product</a>
            <a className="btn btn-link" href="/contact">Contact</a>
          </div>
          
          {/* Contact Information */}
          <div className="col-md-6 col-lg-4">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p><i className="fa fa-map-marker-alt me-3" />2939, Second Floor, Hamilton Road, Kashmere Gate, Delhi-110006</p>
            <p><i className="fa fa-envelope me-3" />lmtekaccessories@gmail.com</p>
            <a href="#" className="navbar-brand p-0">
              <img src="img/logo/lmtek_logo.png" className="rounded m-0" alt="Logo" style={{ height: "60px" }} />
            </a>
          </div>
          
        </div>
      </div>
      
      {/* Footer Copyright */}
      <div className="container px-lg-5">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              Copyright © 2024 <Link className="border-bottom" to="#">LMTEK</Link> All Rights Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Designed and Developed by - <a className="border-bottom" href="https://lalitmittaltechnologies.com/" target="_blank" rel="noopener noreferrer">Lalit Mittal Technologies Pvt Ltd.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
