import React, { useState } from 'react';
import { productData } from '../components/ProductData/ProductData';

const Product = () => {
  const [displayCount, setDisplayCount] = useState(6);
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle "Load More" button click
  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setDisplayCount((prevCount) => prevCount + 6);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div>
      {/* Navbar & Hero Start */}
      <div className="container-xxl position-relative p-0">
        <div className="container-xxl py-5 bg-primary hero-header mb-5">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white animated zoomIn">Product</h1>
                <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a className="text-white" href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item text-white active" aria-current="page">Product</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}

      {/* Portfolio Start */}
      <div className="container-xxl">
        <div className="container">
          <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="position-relative d-inline text-primary ps-4">Our Product</h6>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-12 text-center">
              <ul className="list-inline mb-5" id="portfolio-filters">
                <li className="btn px-3 pe-4 active" data-filter="*">All Products</li>
              </ul>
            </div>
          </div>
          <div className="row g-4 portfolio-container">
            {/* Render the products based on the display count */}
            {productData.slice(0, displayCount).map((product) => (
              <div key={product.id} className="col-lg-4 col-md-6 portfolio-item wow zoomIn" data-wow-delay={product.delay}>
                <div className="position-relative rounded overflow-hidden shadow-lg">
                  <img className="img-fluid w-100" src={product.image} alt={product.name} style={{ height: "350px" }} />
                  <div className="portfolio-overlay">
                    <a className="btn btn-light" href={product.image} data-lightbox="portfolio">
                      <i className="fa fa-plus fa-2x text-primary" />
                    </a>
                    <div className="mt-auto">
                      <small className="text-white">
                        <i className="fa fa-car me-2" />{product.category}
                      </small>
                      <a className="h5 d-block text-white mt-1 mb-0" href="#">
                        {product.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Load More button */}
          <div className="text-center mt-4">
            <button className="btn btn-primary" onClick={handleLoadMore}>
              {isLoading ? <i className="fa fa-spinner fa-spin"></i> : "See more products"}
            </button>
          </div>
        </div>
      </div>
      {/* Portfolio End */}
    </div>
  );
};

export default Product;
