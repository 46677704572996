import React from 'react';

const About = () => {
  return (
    <div>
      {/* Navbar & Hero Start */}
      <div className="container-xxl position-relative p-0">
        <div className="container-xxl py-5 bg-primary hero-header mb-5">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white animated zoomIn">About Us</h1>
                <hr className="bg-white mx-auto mt-0" style={{ width: '90px' }} />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Navbar & Hero End */}

      {/* About Section Start */}
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title position-relative mt-4 pb-2">
                <h6 className="position-relative text-primary ps-4">WHO WE ARE</h6>
                <h2 className="mt-2">Gives you quality services at the best prices.</h2>
              </div>
              <p className="mt-4">In today's fast-paced era, the electronic product market is rapidly evolving. With revolutionary advances in science and technology, there has been significant induction of superior techniques and equipment, transforming the face of audio-visual electronics products.</p>
              <div className="row g-3">
                <div className="col-sm-6">
                  <h6 className="mt-0"><i className="fa fa-check text-primary me-2" />Award-Winning Excellence</h6>
                  <h6 className="mt-3"><i className="fa fa-check text-primary me-2" />Highly Skilled Professionals</h6>
                </div>
                <div className="col-sm-6">
                  <h6 className="mb-"><i className="fa fa-check text-primary me-2" />Round-the-Clock Assistance</h6>
                  <h6 className="mt-3"><i className="fa fa-check text-primary me-2" />Transparent and Fair Pricing</h6>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <a className="btn btn-primary rounded-pill px-4 me-3" href="#">Read More</a>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/about/about.jpeg" alt="About Us" />
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Services Section Start */}
      <div className="container-fluid py-5">
        <div className="container pt-5 pb-3">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-2">
              <div className="service-item d-flex flex-column justify-content-center px-4 mb-4 rounded shadow-lg" style={{ height: '350px' }}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="service-icon flex-shrink-0">
                    <i className="fa fa-lightbulb fa-2x" />
                  </div>
                  <h1 className="display-2 text-white mt-n2 m-0">01</h1>
                </div>
                <h5 className="text-uppercase mb-3">Best Technique and Equipment</h5>
                <p className="m-0">We provide superior techniques and equipment that transform the audio-visual electronics market.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-2">
              <div className="service-item active d-flex flex-column justify-content-center px-4 mb-4 rounded-top shadow-lg" style={{ height: '350px' }}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="service-icon flex-shrink-0">
                    <i className="fa fa-bolt fa-2x" />
                  </div>
                  <h1 className="display-2 text-white mt-n2 m-0">02</h1>
                </div>
                <h5 className="text-uppercase mb-3">Quality Products</h5>
                <p className="m-0">LMTEK brings the latest technology to customers, ensuring quality products meet defined standards.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-2">
              <div className="service-item d-flex flex-column justify-content-center px-4 mb-4 rounded-bottom shadow-lg" style={{ height: '350px' }}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="service-icon flex-shrink-0">
                    <i className="fa fa-users fa-2x" />
                  </div>
                  <h1 className="display-2 text-white mt-n2 m-0">03</h1>
                </div>
                <h5 className="text-uppercase mb-3">Customer Satisfaction</h5>
                <p className="m-0">We keep customer demand and market conditions in mind to deliver exceptional products.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}

      {/* Banner Section Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="bg-banner py-5 px-4 text-center">
            <div className="py-5">
              <h1 className="display-3 text-uppercase text-light mb-4">Top-Quality Electronic Services</h1>
              <h3 className="text-uppercase text-light mb-4">Affordable Solutions for All</h3>
              <p className="mb-4 text-light lh-base">LMTEK aims to increase product value through the lens of customer satisfaction.</p>
              <a className="btn btn-primary mt-2 py-3 px-5" href="#">Discover More</a>
            </div>
          </div>
        </div>
      </div>
      {/* Banner Section End */}

      {/* Vision and Mission Section Start */}
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row mx-0">
            <div className="col-lg-6 px-0">
              <div className="py-4 d-flex align-items-center justify-content-between" style={{ background: "#2B2E4A" }}>
                <img className="img-fluid flex-shrink-0 ml-n5 w-50 mr-4 d-none d-lg-block" src="img/about/banner-left.png" alt="Vision" />
                <div className="text-center text-lg-right px-4 mt-2">
                  <h3 className="text-uppercase text-light mb-3">Vision</h3>
                  <p className="mb-4">We aim to lead the distribution of electronics and robotics components. By working towards excellence in the electronics field, we pursue business through innovation and technology.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0">
              <div className="py-4 d-flex align-items-center justify-content-between" style={{ background: "#1C1E32" }}>
                <div className="text-center text-lg-left px-4 mt-4">
                  <h3 className="text-uppercase text-light">Mission</h3>
                  <p>Our mission is to build long-term relationships with our customers by offering exceptional customer service and continuously expanding our customer base. We focus on asset and investment growth to enhance the company’s future prospects.</p>
                </div>
                <img className="img-fluid flex-shrink-0 mr-n5 w-50 ml-4 d-none d-lg-block" src="img/about/banner-right.png" alt="Mission" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Vision and Mission Section End */}
    </div>
  );
};

export default About;
