export const bestsellerItems = [
  {
    id:7,
    image: 'img/home/product/7.jpg',
    category: 'Imported 10 inch metal basstube',
    name: ' Car metal basstube',
    delay: '0.6s',
  },

  {
    id:8,
    image: 'img/home/product/8.jpg',
    category: '13 inch android screen ',
    name: 'android screen ',
    delay: '0.6s',
  },
  {
    id:9,
    image: 'img/home/product/9.jpg',
    category: '360 degree camera 2',
    name: 'car 360 degree camera 2',
    delay: '0.6s',
  },
  {
    id:10,
    image: 'img/home/product/10.jpg',
    category: '360 degree camera without DVR',
    name: 'car camera without DVR',
    delay: '0.6s',
  },
  {
    id:11,
    image: 'img/home/product/11.jpg',
    category: 'ahd camera With moving line',
    name: 'Ahd camera',
    delay: '0.6s',
  },
  {
    id:12,
    image: 'img/home/product/12.jpg',
    category: 'amp for basstube',
    name: 'Amp for basstube',
    delay: '0.6s',
  },
  ];

  export const portfolioItems = [
    {
      id: 1,
      image: 'img/home/product/1.jpg',
      category: 'Fully Imported',
      name: 'Car Speaker',
      delay: '0.1s',
    },
    {
      id: 2,
      image: 'img/home/product/2.jpg',
      category: '7 inch screen',
      name: 'car screen',
      delay: '0.3s',
    },
    {
      id: 3,
      image: 'img/home/product/3.jpg',
      category: '8 inch basstube with amplifier',
      name: 'car basstube with amplifier ',
      delay: '0.6s',
    },
    {
        id:4,
        image: 'img/home/product/4.jpg',
        category: '9 Androidscreen',
        name: 'Car Androidscreen',
        delay: '0.6s',
      },
      {
        id:5,
        image: 'img/home/product/5.jpg',
        category: 'Touch 9 & 10 Androidscreen',
        name: 'Car Touch',
        delay: '0.6s',
      },
      {
        id:6,
        image: 'img/home/product/6.jpg',
        category: '10 inch basstube with amplifier',
      name: 'car basstube with amplifier ',
        delay: '0.6s',
      },
  ];