import React from 'react'
import {  Route, Routes, Outlet } from "react-router-dom";
import Header from './components/menu/header'
import Footer from './components/menu/footer'

import Home from './pages/home'
import About from './pages/about'
import Product from './pages/product'
import Contact from './pages/contact';
import Categories from './pages/categories';
import ProductDeatails from './pages/ProductDeatails';
import ErorrPage from './pages/ErorrPage';

function App() {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Header />
              <Outlet />
              <Footer />
            </div>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/productdeatails" element={<ProductDeatails />} />

          {/* Add other routes as needed */}
          {/* <Route path="/terms-and-condition" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} /> */}

        </Route>

       

        {/* Error page layout without Header and Footer */}
        <Route path="*" element={<ErorrPage />} />
      </Routes>
    </div>
  );
}

export default App;






